<template>
<!-- 部门管理 -->
  <div class="box100">
    <div class="box100 flexCB">
      <div class="departLeft">
        <div class="flexCB">
          <template v-if="roleBtn[0].power">
            <div class="settingAdd" @click="departAdd">新增部门</div>
          </template>
          <template v-if="roleBtn[2].power">
            <div class="settingAdd" @click="departEdit">编辑部门</div>
          </template>
          <template v-if="roleBtn[1].power">
            <div class="settingAdd" @click="departDel">删除部门</div>
          </template>
        </div>
        <div class="departTree">
          <p class="depTitle">部门列表</p>
          <el-tree
            ref="tree"
            class="filter-tree"
            default-expand-all
            node-key="value"
            :current-node-key="departId"
            highlight-current
            :data="departList"
            @node-click="handleNodeClick"></el-tree>
        </div>
      </div>
      <div class="departRight">
        <div class="flexAC" style="margin-bottom: 16px;">
          <template v-if="roleBtn[3].power">
            <el-button type="primary" class="settingBlue" @click="handStaffBind">绑定员工</el-button>
          </template>
          <template v-if="roleBtn[4].power">
            <el-button type="primary" class="settingBlue" @click="handStaffUnbound">解绑员工</el-button>
          </template>
        </div>
        <div class="table_list">
          <el-table
            ref="staffTable"
            :data="staffList"
            :header-cell-style="{background:'#EBF2FE'}"
            :row-key="getRowKeys"
            @row-click="handleRowClick"
            @selection-change="selectionChange">
            <el-table-column
              prop="staffid"
              type="selection"
              :reserve-selection="true">
            </el-table-column>
            <el-table-column
              label="姓名"
              prop="sname">
            </el-table-column>
            <el-table-column
              label="手机号"
              prop="sphone">
            </el-table-column>
            <el-table-column
              label="性别"
              prop="ssex">
              <template slot-scope="scope">
                <span v-if="scope.row.ssex == 1" >男</span>
                <span v-if="scope.row.ssex != 1" >女</span>
              </template>
            </el-table-column>
            <el-table-column
              label="部门"
              prop="depname">
            </el-table-column>
            <el-table-column
              label="岗位"
              prop="sysvalue">
            </el-table-column>
            <el-table-column
              label="直属上级"
              prop="depusername">
            </el-table-column>
            <el-table-column
              label="角色"
              prop="rolename">
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
          @current-change="pageChange"
          :current-page="page"
          :page-sizes="[10]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          class="settingPage">
        </el-pagination>
      </div>
    </div>

    <!-- 部门【新增-编辑】 -->
    <el-dialog :title="dialogName" :visible.sync="fromShow" center width="600px" class="gyDialog" :close-on-click-modal="false" :before-close="FromCancel">
      <el-form :model="departform" :rules="formRules" ref="department">
        <el-form-item label="部门名称" prop="depname">
          <el-input v-model="departform.depname"  placeholder="请输入部门名称" :disabled="addOrEdit"></el-input>
        </el-form-item>
        <el-form-item label="上级部门" prop="depsuper">
          <el-select v-model="departform.depsuper" placeholder="下拉选择上级部门" :disabled="addOrEdit" style="width: 100%;" >
            <el-option v-for="item in departDown"
                       :key="item.noticesort" :label="item.depname" :value="item.depid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="部门负责人" prop="depuserid">
          <el-select v-model="departform.depuserid" placeholder="下拉选择部门负责人" style="width: 100%;" >
            <el-option v-for="item in personDown"
                       :key="item.noticesort" :label="item.sname" :value="item.staffid">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="FromCancel">取消</div>
        <div class="settingConfirm" @click="FromConfirm">确定</div>
      </div>
    </el-dialog>

    <!-- 绑定员工 -->
    <el-dialog title="绑定员工" :visible.sync="bindShow" center width="600px" class="gyDialog" :close-on-click-modal="false" :before-close="bindFromCancel">
      <el-form :model="bindform" :rules="bindRules" ref="bindStaff">
        <el-form-item label="绑定部门" prop="depid">
          <el-select v-model="bindform.depid" placeholder="下拉选择绑定部门" style="width: 100%;" >
            <el-option v-for="item in bindDepart"
                       :key="item.noticesort" :label="item.depname" :value="item.depid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="绑定员工" prop="staffid">
          <el-select v-model="bindform.staffid" placeholder="下拉选择绑定员工" style="width: 100%;" >
            <el-option v-for="item in bindStaff"
                       :key="item.noticesort" :label="item.sname" :value="item.staffid">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="bindFromCancel">取消</div>
        <div class="settingConfirm" @click="bindFromConfirm">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { departmentQuery, departmentDown, personDown, departmentAdd, departmentEdit, departmentDel, departmentStaff, staffUnbind, departAllDown, staffDown, staffBind } from '@/api/department'
export default {
  props: ['roleBtn'],
  data () {
    return {
      page: 1,
      size: 10,
      total: 0,
      departList: [],
      dialogName: '新增',
      fromShow: false,
      departform: {
        depname: '',
        depsuper: '',
        depuserid: ''
      },
      formRules: {
        depname: [
          { required: true, trigger: 'blur', message: '部门名称不能为空' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
        ],
        depsuper: [
          { required: true, trigger: 'change', message: '请选择上级部门' }
        ],
        depuserid: [
          { required: true, trigger: 'change', message: '请选择部门负责人' }
        ]
      },
      departDown: [],
      personDown: [],
      addOrEdit: false,
      departId: '', // 部门id
      departName: '', // 部门名称
      superiorId: '', // 上级部门id
      superiorName: '', // 上级部门名称
      leadId: '', // 部门负责人
      staffList: [],
      bindIdList: [],
      bindShow: false,
      bindform: {
        depid: '',
        staffid: ''
      },
      bindRules: {
        depid: [
          { required: true, trigger: 'change', message: '请选择绑定部门' }
        ],
        staffid: [
          { required: true, trigger: 'change', message: '请选择绑定员工' }
        ]
      },
      bindDepart: [],
      bindStaff: []
    }
  },
  watch: {
  },
  created () {
    this.init()
  },
  methods: {
    // 部门列表
    init () {
      departmentQuery().then(res => {
        if (res.status === 1) {
          this.departList = this.treeDataFormat(res.data.data)
          this.departId = this.departList[0].value
          this.departName = this.departList[0].label
          this.$nextTick(() => {
            this.$refs.tree.setCurrentKey(this.departId)
            this.getStaff()
          })
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 部门下员工列表
    getStaff () {
      const params = {
        page_index: this.page,
        page_size: this.size,
        depid: this.departId
      }
      departmentStaff(params).then(res => {
        if (res.status === 1) {
          this.staffList = res.data.data
          this.total = res.data.recordcount
          if (this.bindIdList.length > 0) {
              this.$nextTick(() => {
              this.$refs.staffTable.clearSelection(this.bindIdList)
            })
          }
        }
      })
    },
    pageChange (page) {
      this.page = page
      this.getStaff()
    },
    treeDataFormat (treeJson) {
      if (!Array.isArray(treeJson) || treeJson.length <= 0) { return [] }
      const res = []
      treeJson.forEach((item, i) => {
        res.push({
          label: item.depname !== undefined ? item.depname : item.depname,
          value: item.depid !== undefined ? item.depid : item.depid,
          depsuper: item.depsuper !== undefined ? item.depsuper : item.depsuper,
          supername: item.supername !== undefined ? item.supername : item.supername,
          depuserid: item.depuserid !== undefined ? item.depuserid : item.depuserid,
          children: (item.value && item.value.length > 0) ? this.treeDataFormat(item.value) : []
        })
      })
      return res
    },
    // 切换部门
    handleNodeClick (item, node, self) {
      this.departId = item.value
      this.departName = item.label
      this.superiorId = item.depsuper
      this.superiorName = item.supername
      this.leadId = item.depuserid
      this.getStaff()
    },
    // 点击【新增部门】
    departAdd () {
      this.downList()
      this.fromShow = true
      this.addOrEdit = false
      this.dialogName = '新增'
      this.departform.depname = ''
      this.departform.depsuper = ''
      this.departform.depuserid = ''
    },
    // 点击【编辑部门】
    departEdit () {
      if (this.superiorId === '' || this.superiorId === null) {
        this.$message.warning('不能编辑总公司')
      } else {
        this.downList()
        this.fromShow = true
        this.departform.depname = this.departName // 部门名称
        this.departform.depsuper = this.superiorId // 上级部门id
        this.departform.depuserid = this.leadId
        this.dialogName = '编辑'
        this.addOrEdit = true
      }
    },
    // 下拉选择
    downList () {
      departmentDown().then(res => {
        if (res.status === 1) {
          this.departDown = res.data.data
        }
      })
      personDown().then(res => {
        if (res.status === 1) {
          this.personDown = res.data.data
        }
      })
    },
    // [新增-编辑]提交
    FromConfirm () {
      this.$refs.department.validate((valid) => {
        if (!valid) return
        if (this.dialogName === '新增') {
          // 部门新增
          const params = {
            depname: this.departform.depname,
            depsuper: this.departform.depsuper,
            depuserid: this.departform.depuserid
          }
          // console.log(params)
          departmentAdd(params).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.FromCancel()
              this.init()
            } else {
              this.$message.error(res.message)
            }
          })
        } else {
          // 部门编辑
          const params = {
            depname: this.departform.depname,
            depid: this.departId,
            depuserid: this.departform.depuserid
          }
          // console.log(params)
          departmentEdit(params).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.FromCancel()
              this.init()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    FromCancel () {
      this.$refs.department.resetFields()
      this.fromShow = false
    },
    // 部门删除
    departDel () {
      this.$confirm('此操作将永久删除该部门, 是否继续?', '删除部门', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        departmentDel({ depid: this.departId }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    getRowKeys (row) {
      return row.staffid
    },
    handleRowClick (row, column, event) {
      this.$refs.staffTable.toggleRowSelection(row)
    },
    selectionChange (val) {
      if (val.length === 0) { this.bindIdList = '' } else { this.bindIdList = val }
    },
    // 绑定员工
    handStaffBind () {
      this.bindShow = true
      this.getBindDown()
    },
    bindFromCancel () {
      this.bindShow = false
      this.$refs.bindStaff.resetFields()
    },
    // 绑定员工下拉框
    getBindDown () {
      departAllDown().then(res => {
        if (res.status === 1) {
          this.bindDepart = res.data.data
        }
      })
      staffDown().then(res => {
        if (res.status === 1) {
          this.bindStaff = res.data.data
        }
      })
    },
    // 绑定员工【表单提交】
    bindFromConfirm () {
      this.$refs.bindStaff.validate((valid) => {
        if (!valid) return
        const params = {
          depid: this.bindform.depid,
          staffid: this.bindform.staffid
        }
        staffBind(params).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.bindFromCancel()
            this.getStaff()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    // 解绑员工
    handStaffUnbound () {
      if (this.bindIdList.length === 0) {
        this.$message.warning('请先选择员工')
      } else {
        const arr = []
        for (let i = 0; i < this.bindIdList.length; i++) {
          arr.push(this.bindIdList[i].staffid)
        }
        this.$confirm('请确实是否对员工进行解绑操作!', '解绑员工', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          staffUnbind({ staffid: arr }).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.getStaff()
            } else {
              this.$message.error(res.message)
            }
          })
        }).catch(() => {})
      }
    }
  }
}
</script>

<style scoped>
.departLeft,.departRight{
  width: 280px;
  height: 100%;
  padding: 16px;
  box-sizing: border-box;
  background: #fff;
}
.departRight{
  width: calc(100% - 288px);
}
.depTitle{
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  margin: 16px 0px;
}
.departTree >>> .el-tree-node__expand-icon{
  position: absolute;
  right: 0;
}
/*.departTree >>> .el-tree-node__content:hover{*/
/*  background: none;*/
/*}*/
.departTree >>> .el-tree-node__content{
  height: 35px;
}
.departTree >>> .el-tree-node:focus > .el-tree-node__content,
.departTree >>> .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content{
  background: transparent !important;
  color: #3C84EF !important;
}
</style>
