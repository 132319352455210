// 部门
import { post } from '@/http/http'

// 部门查询
export const departmentQuery = p => post('api/v1.0/departinfo/depart_info_get', p)

// 部门查询(下拉)
export const departmentDown = p => post('api/v1.0/departinfo/depart_drop_two', p)

// 部门负责人查询(下拉)
export const personDown = p => post('api/v1.0/departinfo/depart_user_drop', p)

// 部门添加
export const departmentAdd = p => post('api/v1.0/departinfo/depart_info_add', p)

// 部门编辑
export const departmentEdit = p => post('api/v1.0/departinfo/depart_info_update', p)

// 部门删除
export const departmentDel = p => post('api/v1.0/departinfo/depart_info_delete', p)

// 员工查询[按部门]
export const departmentStaff = p => post('api/v1.0/departinfo/depart_user_get', p)

// 员工解绑
export const staffUnbind = p => post('api/v1.0/departinfo/depart_user_unbind', p)

// 部门查询(下拉所有,查询筛选用)
export const departAllDown = p => post('api/v1.0/departinfo/depart_drop_get', p)

// 绑定员工(下拉)
export const staffDown = p => post('api/v1.0/departinfo/depart_user_bind_drop', p)

// 员工绑定
export const staffBind = p => post('api/v1.0/departinfo/depart_user_bind', p)
